















































































































import { CreateUserRes, Statistic, UserService } from '@/includes/services/UserService'
import { User } from '@/includes/types/User'
import { InputSetups } from '@/mixins/input-setups'
import UserCard from '@/components/UserCard.vue'
import ConfirmUserStage from '@/components/ConfirmUserStage.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    PageTitle,
    ConfirmUserStage,
    UserCard,
    EmptyData
  }
})
export default class Users extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  private phoneNum = '+'

  userList: Array<User> | null = null

  usersStatus: Array<{ user_id: number, status: string }> = []

  statistics: null | Array<Statistic> = null

  isCreateModalActive = false

  currentCreationStage: CreateUserRes | null = null

  btnLoadingIndicator = false

  isLoading = false

  dates = {
    from: moment().add(-7, 'd').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  showModal = false

  get phoneNumber() {
    return this.phoneNum
  }

  set phoneNumber(value: string) {
    this.phoneNum = `+${ this.formatter(value) }`
  }

  formatter(value: string): string {
    return value.replace(/\+/g, '').replace(/\s+/g, '')
  }

  userStatus(userKey: string): { user_id: number, status: string, error?: string } | undefined {
    return this.usersStatus.find(value => value.user_id === Number.parseInt(userKey.split('.')[0]))
  }

  deleteUserWarning(userKey: string): void {
    this.$confirm({
      title: this.$t('delete_user_dialog_title').toString(),
      content: this.$t('delete_user_dialog_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => this.deleteUser(userKey),
      centered: true
    })
  }

  deleteUser(user_key: string): void {
    this.isLoading = true
    UserService.deleteUser('tg', { user_key })
      .then(() => successNotification())
      .then(this.requestUsers)
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  async createUser(): Promise<void> {
    this.btnLoadingIndicator = true
    try {
      this.currentCreationStage = await UserService.createUser('tg', { phone: this.phoneNumber.trim() })
    } catch (e) {
      errorNotification(e)
    } finally {
      this.btnLoadingIndicator = false
    }
  }

  async confirmUser(data: { code: string, confirmCodeLoading: boolean }): Promise<void> {
    try {
      data.confirmCodeLoading = true

      const response = await UserService.confirmUser('tg', {
        code: data.code,
        key: this.currentCreationStage?.key,
        stage: this.currentCreationStage?.stage
      })

      if (response.stage === 'Complete') {
        successNotification()
        await this.requestUsers()
        await this.getUsersStatus()
        this.currentCreationStage = null
        this.isCreateModalActive = false
      } else {
        this.currentCreationStage = response
      }
    } catch (e: any) {
      this.currentCreationStage = null
      this.isCreateModalActive = false
      errorNotification(e)
    } finally {
      data.confirmCodeLoading = false
    }
  }

  async requestUsers(): Promise<void> {
    this.userList = await this.$store.dispatch('REQUEST_USERS')
  }

  async requestUsersStat(): Promise<void> {
    const { statistics } = await UserService.getUsersStat('tg', { from: this.dates.from, to: this.dates.to })
    this.statistics = statistics
  }

  async getUsersStatus(): Promise<void> {
    const { users } = await UserService.getUsersStatus('tg', {})
    this.usersStatus = users
  }

  async created(): Promise<void> {
    this.isLoading = true

    await this.requestUsers()
    await this.getUsersStatus()
    await this.requestUsersStat()

    this.isLoading = false
  }
}
