








































































































import { User } from '@/includes/types/User'
import UserLicense from '@/includes/logic/UserLicense'
import UserStatisticsMixin from '@/mixins/statistics/User/UserStatisticsMixin'
import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'
import { Statistic } from '@/includes/services/UserService'
import LicenseExpireDate from '@/components/LicenseElements/LicenseExpireDate.vue'
import LicenseButton from './LicenseElements/LicenseButton.vue'

import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import isMobile from 'piramis-js-utils/lib/isMobile'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'

@Component({
  components: {
    LicenseExpireDate,
    LicenseButton,
    VueApexCharts
  },
  data() {
    return {
      isMobile
    }
  }
})export default class UserCard extends Mixins<UserStatisticsMixin>(UserStatisticsMixin) {
  @Prop() user!: User

  @Prop() statistics!: null | Array<Statistic>

  @Prop() dates!: { from: string, to: string }

  @Prop({ default: null }) status !: { user_id: number, status: string, error?: string } | null

  @Emit()
  openWarnModal():boolean {
    return true
  }

  triggersStatisticsRoute = {
    name: 'Triggers_statistics',
    params: {
      id: this.user.key.split('.')[0],
    }
  }

  triggersRoute = {
    name: 'Triggers',
    params: {
      id: this.user.key.split('.')[0],
    }
  }

  buyLink = {
    name: 'Buy',
    params: {
      id: this.userLicense.user.key.split('.')[0],
    },
  }

  chartOptions: ApexOptions = {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [ 0, 100 ],
      },
    },
    xaxis: {
      categories: fromToChartTicks(this.dates.from, this.dates.to).timeTicks.map(t => moment(t).format('LL')),
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '8px',
          fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => value.toFixed(0),
      },
    },
    tooltip: {
      x: { show: true },
    }
  }

  get currentLicenseTitle():string {
    if (this.userLicense.isLicenseExpired && this.userLicense.licenseType !== '') {
      return this.$t('license_expired').toString()
    }

    if (this.userLicense.licenseType === '') {
      return this.$t('license_none').toString()
    }

    return ''
  }

  get chart(): ApexChartSeries | null {
    if (this.statistics !== null) {
      return [ this.prepareCountUserStat(this.statistics, Number.parseInt(this.user.key.split('.')[0]), this.dates.from, this.dates.to) ]
    } else {
      return null
    }
  }

  get tooltipTitle(): string {
    if (this.status && this.status.error) {
      return this.status.error
    }

    return this.$t('user_active_status').toString()
  }

  get userLicense() {
    return new UserLicense(this.user)
  }

  get isStatusOk():boolean {
    return !!this.status && this.status.status === 'ok'
  }

  handleStatus():void {
    if (!this.isStatusOk) {
      this.openWarnModal()
    }
  }
}
