










import UserLicense from '../../includes/logic/UserLicense'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class LicenseExpireDate extends Vue {
  @Prop() userLicense!: UserLicense

  get licenseExpireDate():string {
    return this.$tc('license_expire_after', this.userLicense.expireAfterDays, [ this.userLicense.expireAfterDays ]).toString()
  }
}
