import { Statistic } from '@/includes/services/UserService'
import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'

import { cloneDeep } from 'lodash'
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class UserStatisticsMixin extends Vue {

    prepareCountUserStat(statistics: Array<Statistic>, userKey: number, from, to): { name: string, data: Array<number> } {
        const { timeTicks, dataTicks } = fromToChartTicks(from, to)

        const name = this.$t('user_count').toString();
        const data = dataTicks;

        statistics.forEach((record) => {
            if (record.userId === userKey) {
                const matchIndex = timeTicks.indexOf(record.time)

                data[matchIndex] = record.count
            }
        })

        return cloneDeep({
            name,
            data
        })
    }
}