import moment from 'moment'
import { User } from '@/includes/types/User'
import { upperFirst } from 'lodash'

export default class UserLicense {

    user: User

    static LICENSE_TYPES = {
        '0': '',
        '1': 'start',
        '2': 'advanced',
        '3': 'ultimate',
    }

    constructor(channel: User) {
        this.user = channel
    }

    get licenseType(): string {
        return upperFirst(UserLicense.LICENSE_TYPES[this.user.license_type])
    }

    get licenseTime() {
        return moment(this.user.license_time).format('DD.MM.YYYY HH:mm:ss')
    }

    get isLicenseExpired() {
        return moment().isAfter(this.user.license_time)
    }

    get expiresSoon() {
        return moment(this.user.license_time).diff(moment(), 'd') < 7
    }

    get expireAfterDays() {
        return moment(this.user.license_time).diff(moment(), 'd')
    }
}
