











import UserLicense from '../../includes/logic/UserLicense'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class LicenseButton extends Vue {
  @Prop() userLicense!: UserLicense

  buyLink = {
    name: 'Buy',
    params: {
      id: this.userLicense.user.key.split('.')[0],
    },
  }

  get licenseButtonTitle():string {
    if (this.userLicense.licenseType === '') {
      return this.$t('buy_license').toString()
    } else {
      if (this.userLicense.isLicenseExpired) {
        return this.$t('buy_license').toString()
      } else {
        return this.$t('button_license_prolongate').toString()
      }
    }
  }

  get buttonLicenseType():string {
    if (this.userLicense.licenseType === '' || this.userLicense.isLicenseExpired || this.userLicense.expiresSoon) {
      return 'danger'
    }

    return ''
  }

}
