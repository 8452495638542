




























































import { InputSetups } from '@/mixins/input-setups'
import { CreateUserStage } from '@/includes/services/UserService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Emit, Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  components: {
    ConfigField
  }
})
export default class ConfirmUserStage extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  code = ''

  confirmUserLoading = false

  password = ''

  @Prop({ type: String, required: true }) stage!: CreateUserStage

  @Emit('onCodeConfirm')
  confirmCode(): { code: string, confirmUserLoading: boolean } {
    const codeCopy = this.code.trim()
    this.code = ''
    return { code: codeCopy, confirmUserLoading: this.confirmUserLoading }
  }

  @Emit('onPasswordConfirm')
  confirmPassword(): { code: string, confirmUserLoading: boolean } {
    const passwordCopy = this.password.trim()
    this.password = ''
    return { code: passwordCopy, confirmUserLoading: this.confirmUserLoading }
  }
}
