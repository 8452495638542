var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('div',{staticClass:"users"},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"mt-2 md:mt-0",attrs:{"type":"primary"},on:{"click":function($event){_vm.isCreateModalActive = true}}},[_vm._v(" "+_vm._s(_vm.$t('add_user'))+" ")])]},proxy:true}])}),_c('a-modal',{attrs:{"footer":null},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"p-5"},[_c('a-alert',{attrs:{"type":"warning"}},[_c('div',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('inactive_users_alert'))},slot:"description"})])],1)]),(_vm.userList)?[(_vm.userList.length)?_c('a-row',{attrs:{"type":"flex","gutter":[16, 16]}},_vm._l((_vm.userList),function(user,index){return _c('a-col',{key:index,attrs:{"xs":24,"sm":12,"md":12,"xl":8}},[_c('user-card',{attrs:{"user":user,"statistics":_vm.statistics,"dates":_vm.dates,"status":_vm.userStatus(user.key)},on:{"deleteUser":_vm.deleteUserWarning,"open-warn-modal":function($event){_vm.showModal = true}}})],1)}),1):_c('empty-data',{attrs:{"description":_vm.$t('users_list_empty')}})]:_c('a-spin',{staticClass:"w-full text-center"}),_c('a-modal',{attrs:{"centered":"","title":_vm.$t('add_user'),"footer":null},on:{"cancel":function($event){_vm.phoneNumber = ''}},model:{value:(_vm.isCreateModalActive),callback:function ($$v) {_vm.isCreateModalActive=$$v},expression:"isCreateModalActive"}},[(!_vm.currentCreationStage)?_c('div',[_c('a-alert',[_c('div',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('add_user_modal_hello_alert'))},slot:"description"})]),_c('text-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': this,
              'key': 'phoneNumber',
              'validation': 'required',
              'prefix': 'stage_',
            }
          }}}),_c('a-divider'),_c('div',{staticClass:"flex justify-end"},[_c('a-button',{staticClass:"btn-success",attrs:{"color":"success","loading":_vm.btnLoadingIndicator,"disabled":_vm.phoneNumber.length < 4},on:{"click":_vm.createUser}},[_vm._v(" "+_vm._s(_vm.$t('add_user_confirm'))+" ")])],1)],1):_vm._e(),(_vm.currentCreationStage && _vm.currentCreationStage.stage)?_c('confirm-user-stage',{attrs:{"stage":_vm.currentCreationStage.stage},on:{"onCodeConfirm":_vm.confirmUser,"onPasswordConfirm":_vm.confirmUser}}):_vm._e()],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }