var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-user-code-stage"},[(_vm.stage === 'Code')?_c('div',[_c('a-alert',[_c('div',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('confirm_user_stage_code_alert'))},slot:"description"})]),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: this,
          key: 'code',
          prefix: 'stage_',
          validation: 'required|length:5'
        }
      }}}),_c('div',{staticClass:"flex justify-end"},[_c('a-button',{staticClass:"btn-success",attrs:{"loading":_vm.confirmUserLoading,"disabled":!_vm.code},on:{"click":_vm.confirmCode}},[_vm._v(" "+_vm._s(_vm.$t('stage_confirm_code'))+" ")])],1)],1):_vm._e(),(_vm.stage === 'Password')?_c('div',[_c('password-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: this,
          key: 'password',
          prefix: 'stage_',
          validation: 'required'
        }
      }}}),_c('div',{staticClass:"flex justify-end"},[_c('a-button',{staticClass:"btn-success",attrs:{"loading":_vm.confirmUserLoading,"disabled":!_vm.password},on:{"click":_vm.confirmPassword}},[_vm._v(" "+_vm._s(_vm.$t('stage_confirm_password'))+" ")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }